var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.handleOutsideClick),expression:"handleOutsideClick"}],ref:"mobile_place_staff_filter",staticClass:"offcanvas offcanvas-right",attrs:{"id":"mobile_place_staff_filter"}},[_c('button',{staticStyle:{"display":"none"},attrs:{"id":"mobile_place_staff_filter_toggle"}}),_c('div',{staticClass:"offcanvas-header d-flex align-items-center justify-content-between pb-5"},[_c('h4',{staticClass:"font-weight-bold m-0"},[_vm._v(" "+_vm._s(_vm.$t("calendarFilters.mobilePlaceAndStaffFilter.title"))+" ")]),_c('a',{attrs:{"href":"#","id":"mobile_place_staff_filter_close"},on:{"click":function($event){return _vm.closeOffcanvas('mobile_place_staff_filter')}}},[_c('i',{staticClass:"ki ki-close icon-md"})])]),_c('perfect-scrollbar',{staticClass:"offcanvas-content mr-n5 scroll",staticStyle:{"max-height":"85vh","position":"relative"}},[_c('div',{staticClass:"d-flex justify-content-between align-items-center mb-7"},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.collapse-places-filter",modifiers:{"collapse-places-filter":true}}],staticClass:"m-1 bb-colapse mobile-places-filter-collapse",on:{"click":function($event){_vm.placesIsOpen != _vm.placesIsOpen}}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('i',{staticClass:"mr-7",class:_vm.placesArrowClass}),_c('span',[_vm._v(_vm._s(_vm.$t("calendarFilters.mobilePlaceAndStaffFilter.placesLabel")))])])]),(
          _vm.$hasRole(_vm.acl.roles.owner) ||
            _vm.$hasPermission(_vm.acl.permissions.manageAccount) ||
            _vm.$hasPermission(_vm.acl.permissions.managePlace)
        )?_c('button',{staticClass:"pl-8",on:{"click":function($event){return _vm.createPlace()}}},[_c('svg',{staticClass:"feather feather-plus",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"18","height":"18","viewBox":"0 0 24 24","fill":"none","stroke":"currentColor","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}},[_c('line',{attrs:{"x1":"12","y1":"5","x2":"12","y2":"19"}}),_c('line',{attrs:{"x1":"5","y1":"12","x2":"19","y2":"12"}})])]):_vm._e()],1),_c('b-collapse',{attrs:{"id":"collapse-places-filter"},model:{value:(_vm.placesIsOpen),callback:function ($$v) {_vm.placesIsOpen=$$v},expression:"placesIsOpen"}},[_c('div',{staticClass:"places-container"},_vm._l((_vm.allPlaces),function(place){return _c('div',{key:place.id,staticClass:"place-item",class:place.id === _vm.currentPlace.id ? 'active' : '',on:{"click":function($event){return _vm.setCurrentPlace(place)}}},[_c('i',{staticClass:"fas fa-store"}),_c('span',[_vm._v(_vm._s(place.name))])])}),0)]),_c('div',{staticClass:"d-flex justify-content-between align-items-center mt-7 mb-7"},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.collapse-staff-filter",modifiers:{"collapse-staff-filter":true}}],staticClass:"m-1 bb-colapse mobile-staff-filter-collapse",on:{"click":function($event){_vm.staffIsOpen != _vm.staffIsOpen}}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('i',{staticClass:"mr-7",class:_vm.staffArrowClass}),_c('span',[_vm._v(_vm._s(_vm.$t("calendarFilters.mobilePlaceAndStaffFilter.staffLabel")))])])]),(
          _vm.$hasRole(_vm.acl.roles.owner) ||
            _vm.$hasPermission(_vm.acl.permissions.manageAccount) ||
            _vm.$hasPermission(_vm.acl.permissions.managePlace)
        )?_c('button',{staticClass:"pl-8",on:{"click":function($event){_vm.close();
          _vm.createCalendar();}}},[_c('svg',{staticClass:"feather feather-plus",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"18","height":"18","viewBox":"0 0 24 24","fill":"none","stroke":"currentColor","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}},[_c('line',{attrs:{"x1":"12","y1":"5","x2":"12","y2":"19"}}),_c('line',{attrs:{"x1":"5","y1":"12","x2":"19","y2":"12"}})])]):_vm._e()],1),_c('b-collapse',{attrs:{"id":"collapse-staff-filter"},model:{value:(_vm.staffIsOpen),callback:function ($$v) {_vm.staffIsOpen=$$v},expression:"staffIsOpen"}},[_c('div',{staticClass:"mobile-staff-filter-toggle-all",class:_vm.allStaffSelected ? 'active' : ''},[_c('v-checkbox',{attrs:{"color":"secondary","hide-details":""},model:{value:(_vm.allStaffSelected),callback:function ($$v) {_vm.allStaffSelected=$$v},expression:"allStaffSelected"}}),_c('span',[_vm._v(_vm._s(_vm.$t("calendarFilters.mobilePlaceAndStaffFilter.allStaffLabel")))])],1),_vm._l((_vm.staff),function(staffMember){return _c('div',{key:staffMember.id,staticClass:"bb-staff-filter-item"},[_c('div',{staticClass:"mobile-staff-filter-item",class:staffMember.checked ? 'active' : ''},[_c('v-checkbox',{attrs:{"color":staffMember.calendar.color,"hide-details":""},on:{"click":_vm.toggleAllSelected},model:{value:(staffMember.checked),callback:function ($$v) {_vm.$set(staffMember, "checked", $$v)},expression:"staffMember.checked"}}),_c('span',[_vm._v(_vm._s(staffMember.fullName))])],1)])})],2)],1),_c('PlaceFormModal',{ref:"placeFormModal"}),_c('CalendarFormModal',{ref:"calendarFormModal"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }