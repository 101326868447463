<template>
  <div class="bb-staff-filter">
    <BBDropdown ref="staffDropdown">
      <template v-slot:toggle="{ isOpen }">
        <div class="bb-staff-filter-toggle" :class="isOpen ? 'shadowed' : ''">
          <span>{{ $t("calendarFilters.staffFilter.allStaff") }}</span>
          <i class="ki ki-arrow-down"></i>
        </div>
      </template>
      <template v-slot:items>
        <div class="bb-staff-filter-header-item">
          <v-checkbox
            color="secondary"
            v-model="allStaffSelected"
            hide-details
          ></v-checkbox>
          <span>{{ $t("calendarFilters.staffFilter.allStaff") }}</span>
        </div>
        <div
          class="bb-staff-filter-item"
          v-for="staffMember in staff"
          :key="staffMember.id"
        >
          <div class="bb-staff-filter-item-present">
            <v-checkbox
              :color="staffMember.calendar.color"
              v-model="staffMember.checked"
              @click="toggleAllSelected"
              hide-details
            ></v-checkbox>
            <span>{{ staffMember.fullName }}</span>
          </div>
          <div
            class="bb-staff-filter-item-actions"
            v-if="
              $hasRole(acl.roles.owner) ||
                $hasPermission(acl.permissions.manageAccount) ||
                $hasPermission(acl.permissions.managePlace)
            "
          >
            <i @click="editCalendar(staffMember)" class="ki ki-more-hor"></i>
          </div>
        </div>
        <div
          class="bb-staff-filter-footer-item"
          @click="createCalendar"
          v-if="
            $hasRole(acl.roles.owner) ||
              $hasPermission(acl.permissions.manageAccount) ||
              $hasPermission(acl.permissions.managePlace)
          "
        >
          <i class="fas fa-plus"></i>
          <span>{{ $t("calendarFilters.staffFilter.createStaff") }}</span>
        </div>
      </template>
    </BBDropdown>
    <CalendarFormModal ref="calendarFormModal" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BBDropdown from "@/view/content/BBDropdown.vue";
// import calendarFormMixin from "@/core/mixins/calendarFormMixin.js";
import calendarFormModalMixin from "@/core/mixins/calendarFormModalMixin.js";
import CalendarFormModal from "@/view/layout/extras/modal/CalendarFormModal.vue";

export default {
  name: "StaffFilter",
  components: { BBDropdown, CalendarFormModal },
  mixins: [calendarFormModalMixin],
  data() {
    return {
      allStaffSelected: true,
      staff: []
    };
  },
  methods: {
    /**
     * Map staff for current place to staff data property
     * @return {void}
     */
    mapStaff() {
      this.staff = [];
      this.allStaffSelected = true;
      let staff = JSON.parse(JSON.stringify(this.currentPlace.staff));
      staff.forEach(staffMember => {
        staffMember.checked = true;
        this.staff.push(staffMember);
      });
    },

    /**
     * Toggle allStaffSelected data property
     * @return {void}
     */
    toggleAllSelected() {
      if (
        this.staff.filter(staffMember => staffMember.checked === false).length >
        0
      ) {
        this.allStaffSelected = false;
      } else {
        this.allStaffSelected = true;
      }
    }
  },
  computed: {
    ...mapGetters([
      "currentPlace", 
      ])
  },
  watch: {
    /**
     * Watch for currentPlace change
     */
    currentPlace() {
      this.mapStaff();
    },
    /**
     * Watch for allStaffSelected change
     */
    allStaffSelected(allStaffSelected) {
      if (allStaffSelected) {
        this.staff.map(staffMember => (staffMember.checked = true));
      }
    },
    /**
     * Watch for staff change
     */
    staff: {
      immediate: true,
      deep: true,
      handler() {
        if (this.staff.length > 0) {
          let checkedCalendarIds = [];
          this.staff.forEach(staffMember => {
            if (staffMember.checked) {
              checkedCalendarIds.push(staffMember.calendar.id);
            }
          });
          this.$emit("change", checkedCalendarIds);
        }
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/sass/bb-styles/components/staff-filter.scss";
</style>
