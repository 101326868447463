var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bb-staff-filter"},[_c('BBDropdown',{ref:"staffDropdown",scopedSlots:_vm._u([{key:"toggle",fn:function({ isOpen }){return [_c('div',{staticClass:"bb-staff-filter-toggle",class:isOpen ? 'shadowed' : ''},[_c('span',[_vm._v(_vm._s(_vm.$t("calendarFilters.staffFilter.allStaff")))]),_c('i',{staticClass:"ki ki-arrow-down"})])]}},{key:"items",fn:function(){return [_c('div',{staticClass:"bb-staff-filter-header-item"},[_c('v-checkbox',{attrs:{"color":"secondary","hide-details":""},model:{value:(_vm.allStaffSelected),callback:function ($$v) {_vm.allStaffSelected=$$v},expression:"allStaffSelected"}}),_c('span',[_vm._v(_vm._s(_vm.$t("calendarFilters.staffFilter.allStaff")))])],1),_vm._l((_vm.staff),function(staffMember){return _c('div',{key:staffMember.id,staticClass:"bb-staff-filter-item"},[_c('div',{staticClass:"bb-staff-filter-item-present"},[_c('v-checkbox',{attrs:{"color":staffMember.calendar.color,"hide-details":""},on:{"click":_vm.toggleAllSelected},model:{value:(staffMember.checked),callback:function ($$v) {_vm.$set(staffMember, "checked", $$v)},expression:"staffMember.checked"}}),_c('span',[_vm._v(_vm._s(staffMember.fullName))])],1),(
            _vm.$hasRole(_vm.acl.roles.owner) ||
              _vm.$hasPermission(_vm.acl.permissions.manageAccount) ||
              _vm.$hasPermission(_vm.acl.permissions.managePlace)
          )?_c('div',{staticClass:"bb-staff-filter-item-actions"},[_c('i',{staticClass:"ki ki-more-hor",on:{"click":function($event){return _vm.editCalendar(staffMember)}}})]):_vm._e()])}),(
          _vm.$hasRole(_vm.acl.roles.owner) ||
            _vm.$hasPermission(_vm.acl.permissions.manageAccount) ||
            _vm.$hasPermission(_vm.acl.permissions.managePlace)
        )?_c('div',{staticClass:"bb-staff-filter-footer-item",on:{"click":_vm.createCalendar}},[_c('i',{staticClass:"fas fa-plus"}),_c('span',[_vm._v(_vm._s(_vm.$t("calendarFilters.staffFilter.createStaff")))])]):_vm._e()]},proxy:true}])}),_c('CalendarFormModal',{ref:"calendarFormModal"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }