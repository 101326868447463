<template>
  <div class="bb-period-filter" v-click-outside="hideSmallCalendar">
    <div class="bb-period-filter-buttons">
      <button
        class="bb-period-filter-prev-btn"
        @click="switchPeriod('prev')"
        :class="shadowClass"
      >
        <i class="la la-angle-left"></i>
      </button>
      <div
        class="bb-period-filter-title"
        @click="toggleSmallCalendar"
        :class="shadowClass"
      >
        <span>{{ periodTitle }}</span>
      </div>
      <button
        class="bb-period-filter-next-btn"
        @click="switchPeriod('next')"
        :class="shadowClass"
      >
        <i class="la la-angle-right"></i>
      </button>
    </div>
    <b-calendar
      ref="smallCalendar"
      :hide-header="true"
      today-variant="success"
      :locale="locale"
      :hidden="smallCalendarHidden"
      :date-info-fn="dateClass"
      :start-weekday="1"
      @context="onContext"
      @selected="onDateSelect"
      v-model="selectedPeriodDate"
    >
      <template v-slot:nav-prev-month
        ><i class="la la-arrow-left b-calendar-custom-nav"></i
      ></template>
      <template v-slot:nav-next-month
        ><i class="la la-arrow-right b-calendar-custom-nav"></i
      ></template>
      <template v-slot:nav-this-month :disabled="disabled">
        <span class="b-calendar-month-string" style="margin-right:5px;">{{
          monthString
        }}</span>
        <span class="b-calendar-year-string">{{ yearString }}</span>
      </template>
    </b-calendar>
  </div>
</template>

<script>
import moment from "moment";
import i18nService from "@/core/services/i18n.service.js";

export default {
  name: "PeriodFilter",
  props: {
    /**
     * Page's main calendar (full calendar plugin)
     * @type {Object}
     */
    fullCalendar: {
      type: Object,
      required: false
    },

    /**
     * Period filter title
     * @type {String}
     */
    periodTitle: {
      type: String,
      required: false,
      default: "Period filter"
    }
  },
  data() {
    return {
      selectedPeriodDate: null,
      smallCalendarHidden: true,
      monthString: "",
      yearString: "",
      locale: i18nService.getActiveLanguage()
    };
  },
  methods: {
    /**
     * Switch view period for fullCalendar prop
     * @param {String} switchType
     * @return {void}
     */
    switchPeriod(switchType) {
      if (this.fullCalendar) {
        switch (switchType.toLowerCase()) {
          case "prev":
            this.fullCalendar.prev();
            this.selectedPeriodDate = this.fullCalendar.currentData.viewApi.activeStart;
            break;
          case "next":
            this.fullCalendar.next();
            this.selectedPeriodDate = this.fullCalendar.currentData.viewApi.activeStart;
            break;
          default:
            break;
        }

        this.emitChangeEvent();
      }
    },

    /**
     * Toggle small calendar visibility
     * @return {void}
     */
    toggleSmallCalendar() {
      this.smallCalendarHidden = !this.smallCalendarHidden;
    },

    /**
     * Hide small calendar
     * @return {void}
     */
    hideSmallCalendar() {
      this.smallCalendarHidden = true;
    },

    /**
     * Return class for every small calendar cell based on full calendar
     * @param {String} ymd
     * @param {Date} date
     * @return {String}
     */
    dateClass(ymd, date) {
      return date.getTime() >=
        this.fullCalendar.currentData.viewApi.activeStart.getTime() &&
        date.getTime() <
          this.fullCalendar.currentData.viewApi.activeEnd.getTime()
        ? this.dateCurrentViewClass
        : "";
    },

    /**
     * Update monthString and yearString data properties on small calendar context
     * @param {Object} ctx
     * @return {void}
     */
    onContext(ctx) {
      let activeDate = ctx.activeYMD;
      this.monthString = moment(activeDate)
        .locale(this.locale)
        .format("MMMM");
      this.yearString = moment(activeDate).format("YYYY");
    },

    /**
     * Change full calendar view on small calendar date select
     * @param {String} ymd
     * @return {void}
     */
    onDateSelect(ymd) {
      if (this.fullCalendar) {
        this.fullCalendar.changeView(
          this.fullCalendar.currentData.currentViewType,
          ymd
        );
        this.emitChangeEvent();
      }
    },

    /**
     * Emit change event
     * @emit change
     */
    emitChangeEvent() {
      let periodObject = {
        from: moment(this.fullCalendar.currentData.viewApi.activeStart).format(
          "YYYY-MM-DD"
        ),
        to: moment(this.fullCalendar.currentData.viewApi.activeEnd).format(
          "YYYY-MM-DD"
        )
      };

      this.$emit("change", periodObject);
    }
  },
  computed: {
    /**
     * Return class name for date current view class
     * @return {String}
     */
    dateCurrentViewClass() {
      if (this.fullCalendar) {
        if (this.fullCalendar.currentData.currentViewType === "timeGridDay") {
          return "current-view single";
        }
        return "current-view";
      }
      return "";
    },

    /**
     * Return shadow class if smallCalendar is visible
     * @return {String}
     */
    shadowClass() {
      if (!this.smallCalendarHidden) {
        return "shadowed";
      }

      return "";
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/sass/bb-styles/components/period-filter.scss";
</style>
