<template>
  <div id="mobile_event_popover" v-show="isOpen" v-if="appointment">
    <div v-if="appointment.type !== 'BLOCK_TIME'">
      <div class="d-flex justify-content-between align-items-center">
        <div class="event-popover-details">
          <span class="event-time">{{
            appointment.from + " - " + appointment.to
          }}</span>
          <div v-if="currentUser.isInLawIndustry">
            <span class="event-client">{{ 
              appointment.subject.subjectNumber 
              }}</span><br>
            <span
              class="event-service"
              >{{ appointment.subject.courtName }} - {{ appointment.subject.courtNumber }}</span
            ><br>
          </div>
          <span class="event-client">{{ appointmentTitle }}</span>
          <span
            v-if="appointment.service.deleted == true"
            class="event-service-deleted"
            >{{ appointment.service.name }}</span
          >
          <span v-else class="event-service">{{
            appointment.service.name
          }}</span>
          <span class="event-client">{{
            $t("calendar.appointment.employee")
          }}</span>
          <span class="event-service"
            >{{ appointment.employee.first_name }}
            {{ appointment.employee.last_name }}</span
          >
        </div>
        <div v-if="canManageAppointment() && appointment.type === 'REGULAR'" class="event-contact-buttons">
          <a
            v-if="appointment.client && appointment.client.phone"
            :href="'tel:' + appointment.client.phone"
          >
            <i class="fas fa-phone-alt"></i>
          </a>
          <a
            v-if="appointment.client && appointment.client.email"
            :href="'mailto:' + appointment.client.email"
          >
            <i class="flaticon2-black-back-closed-envelope-shape"></i>
          </a>
        </div>
      </div>
      <div v-if="canManageAppointment()">
        <div class="event-notes">
          <span class="event-notes-label"
            >{{ $t("calendar.appointment.notesLabel") }}:
          </span>
          <span class="event-notes-text">{{
            appointment.notes
              ? appointment.notes
              : $t("calendar.appointment.noNotesText")
          }}</span>
        </div>
        <div>
          <AppointmentsList
            v-if="appointment.type === 'REGULAR' && appointment.client"
            :client="appointment.client"
            :paginationDisabled="true"
          />
          <div
            v-if="appointment.status != 'CANCELED'"
            class="event-action-buttons"
          >
            <button class="event-cancel-btn" @click="cancelAppointment">
              {{ $t("calendar.appointment.cancelAppointment") }}
            </button>
            <button class="event-edit-btn" @click="editAppointment">
              {{ $t("calendar.appointment.editAppointment") }}
            </button>
          </div>
          <div v-else class="event-action-buttons">
            <button
              class="event-cancel-btn"
              style="opacity: 60%; background-color: lightgrey"
              disabled
            >
              {{ $t("calendar.appointment.cancelAppointment") }}
            </button>
            <button
              class="event-edit-btn"
              style="opacity: 60%; background-color: lightgrey"
              disabled
            >
              {{ $t("calendar.appointment.editAppointment") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="appointment.type === 'BLOCK_TIME'">
      <div class="d-flex justify-content-between align-items-center">
        <div class="event-popover-details">
          <span class="event-time">{{
            appointment.from + " - " + appointment.to
          }}</span>
          <span class="event-client">{{ appointment.notes }}</span>
        </div>
      </div>
      <div class="event-action-buttons">
        <button class="event-cancel-btn" @click="removeBlockTime">
          {{ $t("calendar.blockTime.removeBlockTime") }}
        </button>
        <button class="event-edit-btn" @click="editBlockTime">
          {{ $t("calendar.blockTime.editBlockTime") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import AppointmentsList from "@/view/pages/components/Client/AppointmentsList.vue";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { mapGetters } from "vuex";

export default {
  name: "MobileEventPopover",
  props: {
    /**
     * Appointment (event) object
     * @type {Object}
     */
    appointment: {
      type: Object,
      required: false,
    },
  },
  components: { AppointmentsList },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    ...mapGetters(["currentUser"]),

    /**
     * Return appointment title
     * @return {String}
     */
    appointmentTitle() {
      let appointmentTitle = "Unknown";
      if(this.appointment) {
        if(this.appointment.type === "REGULAR") {
            appointmentTitle = this.appointment.client?.fullName ?? "Unknown";
          }
          if(this.appointment.type === "GROUP") { 
            appointmentTitle = this.appointment.clients ? 
              `${this.appointment.clients.length}/${this.appointment.service?.sameTimePeople ?? '*'} ${this.$t("calendar.calendarEventCard.clientsLabel")}` :
              "Unknown";
          }
      }

      return appointmentTitle;
    }
  },
  methods: {
    /**
     * Open mobile event popover element
     * @return {void}
     */
    open() {
      let targetElement = document.querySelector("#mobile_event_popover");
      this.isOpen = true;
      disableBodyScroll(targetElement);
      if (!this.appointment.client) {
        this.appointment.client = this.appointment.user;
      }
    },

    /**
     * Close mobile event popover element
     * @return {void}
     */
    close() {
      let targetElement = document.querySelector("#mobile_event_popover");
      this.isOpen = false;
      enableBodyScroll(targetElement);
    },

    /**
     * Emit cancel appointment event
     * @emits cancelAppointment
     */
    cancelAppointment() {
      this.$emit("cancelAppointment", this.appointment);
    },

    /**
     * Emit edit appointment event
     * @emits editAppointment
     */
    editAppointment() {
      this.$emit("editAppointment", this.appointment);
    },

    /**
     * Emit editBlockTime event
     * @emits editBlockTime
     */
    editBlockTime() {
      this.$emit("editBlockTime", this.appointment);
    },

    /**
     * Emit removeBlockTime event
     * @emits removeBlockTime
     */
    removeBlockTime() {
      this.$emit("removeBlockTime", this.appointment);
    },

    /**
     * Check if current user can manage appointment
     * @param {Object} appointment
     * @return {Boolean}
     */
    canManageAppointment() {
      if (this.appointment.employee.id === this.$store.getters.currentUser.id) {
        return true;
      } else if (
        this.$hasRole(this.acl.roles.staffMember) &&
        this.$hasOnlyBasePermission()
      ) {
        return false;
      }
      return true;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/sass/bb-styles/components/mobile-event-popover.scss";
</style>
