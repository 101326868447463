<template>
  <div id="mobile_view_filter" v-click-outside="handleOutsideClick">
    <button
      class="bb-mobile-view-filter-toggle-btn"
      v-html="selectedViewIcon"
      @click="toggle"
    ></button>
    <div class="bb-mobile-view-filter" v-show="isOpen">
      <div class="mobile-view-filter-title">
        <span>{{ $t("calendarFilters.mobileViewFilter.title") }}</span>
      </div>
      <div class="mobile-view-filter-options">
        <div
          class="mobile-view-filter-option-item"
          v-for="view in calendarViews"
          :key="view.value"
          @click="selectView(view.value)"
        >
          <div class="option-item-present">
            <div class="bb-badge" v-html="view.icon"></div>
            <span>{{ view.text }}</span>
          </div>
          <div
            v-show="view.value === selectedView"
            class="option-item-selected"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-check"
            >
              <polyline points="20 6 9 17 4 12"></polyline>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

export default {
  name: "MobileViewFilter",
  props: {
    /**
     * Page's main calendar (full calendar plugin)
     * @type {Object}
     */
    fullCalendar: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      isOpen: false,
      selectedView: "timeGridThreeDay",
      calendarViews: [
        {
          text: this.$t("calendarFilters.mobileViewFilter.day"),
          value: "timeGridDay",
          icon: `
            <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path 
                    fill-rule="evenodd" 
                    clip-rule="evenodd" 
                    d="M1.04545 4.19048H21.9545C22.2318 4.19048 22.4977 4.30085 22.6938 4.49732C22.8899 4.69378 23 4.96025 23 5.2381V15.7143C23 15.9921 22.8899 16.2586 22.6938 16.4551C22.4977 16.6515 22.2318 16.7619 21.9545 16.7619H1.04545C0.768183 16.7619 0.502267 16.6515 0.306207 16.4551C0.110146 16.2586 0 15.9921 0 15.7143V5.2381C0 4.96025 0.110146 4.69378 0.306207 4.49732C0.502267 4.30085 0.768183 4.19048 1.04545 4.19048ZM1.56818 5.76191V15.1905H21.4318V5.76191H1.56818ZM0.784091 0H22.2159C22.4239 0 22.6233 0.0827804 22.7703 0.23013C22.9174 0.37748 23 0.57733 23 0.785714C23 0.994099 22.9174 1.19395 22.7703 1.3413C22.6233 1.48865 22.4239 1.57143 22.2159 1.57143H0.784091C0.576137 1.57143 0.3767 1.48865 0.229655 1.3413C0.0826093 1.19395 0 0.994099 0 0.785714C0 0.57733 0.0826093 0.37748 0.229655 0.23013C0.3767 0.0827804 0.576137 0 0.784091 0V0ZM0.784091 19.9048H22.2159C22.4239 19.9048 22.6233 19.9875 22.7703 20.1349C22.9174 20.2822 23 20.4821 23 20.6905C23 20.8989 22.9174 21.0987 22.7703 21.2461C22.6233 21.3934 22.4239 21.4762 22.2159 21.4762H0.784091C0.576137 21.4762 0.3767 21.3934 0.229655 21.2461C0.0826093 21.0987 0 20.8989 0 20.6905C0 20.4821 0.0826093 20.2822 0.229655 20.1349C0.3767 19.9875 0.576137 19.9048 0.784091 19.9048Z" 
                    fill="currentColor"
                />
            </svg>

          `
        },
        {
          text: this.$t("calendarFilters.mobileViewFilter.threeDays"),
          value: "timeGridThreeDay",
          icon: `
            <svg width="29" height="19" viewBox="0 0 29 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path 
                    fill-rule="evenodd" 
                    clip-rule="evenodd" 
                    d="M19.7865 19H1.51042C0.676667 19 0 18.335 0 17.5156V1.48438C0 0.665 0.676667 0 1.51042 0H27.4896C28.3233 0 29 0.665 29 1.48438V17.5156C29 18.335 28.3233 19 27.4896 19H19.7865ZM19.9375 17.2188H27.1875V1.78125H19.9375V17.2188ZM18.125 17.2188V1.78125H10.875V17.2188H18.125ZM9.0625 1.78125H1.8125V17.2188H9.0625V1.78125Z" 
                    fill="currentColor"
                />
            </svg>
          `
        }
      ]
    };
  },
  methods: {
    /**
     * Handle outside click
     * @return {void}
     */
    handleOutsideClick(event) {
      if (event.target.classList.contains("offcanvas-overlay")) {
        if (this.isOpen) {
          this.close();
        }
      }
    },

    /**
     * Toggle mobile view filter
     * @emit toggle
     */
    toggle() {
      let targetElement = document.querySelector("#mobile_view_filter");
      this.isOpen = !this.isOpen;
      this.$emit("toggle");
      if (this.isOpen) {
        disableBodyScroll(targetElement);
      } else {
        enableBodyScroll(targetElement);
      }
    },

    /**
     * Close mobile view filter
     * @return {void}
     */
    close() {
      this.isOpen = false;
      let targetElement = document.querySelector("#mobile_view_filter");
      enableBodyScroll(targetElement);
      this.$emit("toggle");
    },

    /**
     * Set selectedView data property
     * @param {String} selectedView
     * @return {void}
     */
    selectView(selectedView) {
      this.selectedView = selectedView;
      this.switchView();
    },

    /**
     * Switch full calendar view
     * @return {void}
     */
    switchView() {
      if (this.fullCalendar) {
        setTimeout(() => {
          this.fullCalendar.changeView(this.selectedView);
          this.emitChangeEvent();
          this.close();
        }, 100);
      }
    },

    /**
     * Emit change event
     * @emit change
     */
    emitChangeEvent() {
      let periodObject = {
        from: moment(this.fullCalendar.currentData.viewApi.activeStart).format(
          "YYYY-MM-DD"
        ),
        to: moment(this.fullCalendar.currentData.viewApi.activeEnd).format(
          "YYYY-MM-DD"
        )
      };

      this.$emit("change", periodObject);
    }
  },
  computed: {
    /**
     * Return svg icon string for selected view
     * @return {String}
     */
    selectedViewIcon() {
      let selectedView = this.calendarViews.find(
        view => view.value === this.selectedView
      );
      if (selectedView) {
        return selectedView.icon;
      }

      return "";
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/sass/bb-styles/components/mobile-view-filter.scss";
</style>
