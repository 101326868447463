import blockTimeForm from "@/core/services/forms/blockTimeForm.js";
import { OPEN_ELEMENT } from "@/core/services/store/offcanvas.module.js";
import { INIT_FORM } from "@/core/services/store/form.module.js";
import { disableBodyScroll } from "body-scroll-lock";

export default {
  methods: {
    /**
     * Init block time form with default values from blockTimeForm object and open block time form element
     * @param {Object|null} fields
     * @return {void}
     */
    createBlockedTime(fields = null) {
      let form = JSON.parse(JSON.stringify(blockTimeForm));
      if (fields) {
        for (const [key, value] of Object.entries(fields)) {
          form.fields[key] = value;
        }
      }
      this.$store.dispatch(INIT_FORM, form);
      document.querySelector("#block_time_form_toggle").click();
      this.$store.dispatch(OPEN_ELEMENT, "block_time_form");
      let targetElement = document.querySelector("#block_time_form");
      disableBodyScroll(targetElement);
    },

    /**
     * Init block time form with edit mode and open block time form element
     * @param {Object} blockedTime
     * @return {void}
     */
    editBlockedTime(blockedTime) {
      let form = JSON.parse(JSON.stringify(blockTimeForm));
      form.mode = "edit";
      form.path = form.path + "/" + blockedTime.id;
      form.fields.date = blockedTime.date;
      form.fields.calendarId = blockedTime.calendarId;
      form.fields.from = blockedTime.from;
      form.fields.to = blockedTime.to;
      form.fields.notes = blockedTime.notes;
      form.fields._method = "PUT";
      form.helpers.blockedTimeId = blockedTime.id;
      this.$store.dispatch(INIT_FORM, form);
      document.querySelector("#block_time_form_toggle").click();
      this.$store.dispatch(OPEN_ELEMENT, "block_time_form");
      let targetElement = document.querySelector("#block_time_form");
      disableBodyScroll(targetElement);
    }
  }
};
