<template>
  <div id="mobile_period_filter">
    <button class="bb-mobile-period-filter-toggle-btn" @click="toggle">
      <span class="b-calendar-month-string" style="margin-right:5px;">{{
        monthString
      }}</span>
      <span class="b-calendar-year-string">{{ yearString }}</span>
      <i class="la la-angle-down"></i>
    </button>
    <div id="mobile_period_filter_overlay" v-show="isOpen">
      <div class="bb-mobile-period-filter" v-click-outside="handleOutsideClick">
        <div class="bb-mobile-header">
          <div class="bb-mobile-header-left">
            <button class="mobile-sidebar-btn" @click="openMobileAside()">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-menu"
              >
                <line x1="3" y1="12" x2="21" y2="12"></line>
                <line x1="3" y1="6" x2="21" y2="6"></line>
                <line x1="3" y1="18" x2="21" y2="18"></line>
              </svg>
            </button>
            <button class="bb-mobile-period-filter-toggle-btn" @click="toggle">
              <span class="b-calendar-month-string" style="margin-right:5px;">{{
                monthString
              }}</span>
              <span class="b-calendar-year-string">{{ yearString }}</span>
            </button>
          </div>
          <div class="bb-mobile-header-right">
            <button @click="navigateSmallCalendar('prev')">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-arrow-left"
              >
                <line x1="19" y1="12" x2="5" y2="12"></line>
                <polyline points="12 19 5 12 12 5"></polyline>
              </svg>
            </button>
            <button @click="navigateSmallCalendar('next')">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-arrow-right"
              >
                <line x1="5" y1="12" x2="19" y2="12"></line>
                <polyline points="12 5 19 12 12 19"></polyline>
              </svg>
            </button>
          </div>
        </div>
        <div class="b-calendar-container">
          <b-calendar
            ref="smallCalendar"
            :hide-header="true"
            today-variant="success"
            :locale="locale"
            :date-info-fn="dateClass"
            :start-weekday="1"
            @context="onContext"
            @selected="onDateSelect"
            v-model="selectedPeriodDate"
          >
          </b-calendar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import mobileAsideMixin from "@/core/mixins/mobileAsideMixin.js";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import i18nService from "@/core/services/i18n.service.js";

export default {
  name: "MobilePeriodFilter",
  mixins: [mobileAsideMixin],
  props: {
    /**
     * Page's main calendar (full calendar plugin)
     * @type {Object}
     */
    fullCalendar: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      isOpen: false,
      selectedPeriodDate: null,
      monthString: "",
      yearString: "",
      locale: i18nService.getActiveLanguage()
    };
  },
  methods: {
    /**
     * Handle outside click
     * @return {void}
     */
    handleOutsideClick(event) {
      if (
        event.target.id === "mobile_period_filter_overlay" ||
        event.target.classList.contains("menu-text")
      ) {
        if (this.isOpen) {
          this.toggle();
        }
      }
    },

    /**
     * Navigate small calendar
     * @param {String} direction
     * @return {void}
     */
    navigateSmallCalendar(direction) {
      if (direction === "next") {
        this.$refs.smallCalendar.gotoNextMonth();
      }

      if (direction === "prev") {
        this.$refs.smallCalendar.gotoPrevMonth();
      }
    },

    /**
     * Toggle mobile period filter
     * @return {void}
     */
    toggle() {
      let targetElement = document.querySelector("#mobile_period_filter");
      this.isOpen = !this.isOpen;
      if (this.isOpen) {
        disableBodyScroll(targetElement);
      } else {
        //enableBodyScroll(targetElement);
        clearAllBodyScrollLocks();
      }
    },

    /**
     * Return class for every small calendar cell based on full calendar
     * @param {String} ymd
     * @param {Date} date
     * @return {String}
     */
    dateClass(ymd, date) {
      if (this.fullCalendar) {
        let start = this.fullCalendar.currentData.viewApi.activeStart;
        let end = this.fullCalendar.currentData.viewApi.activeEnd;
        if (
          date.getTime() >= start.getTime() &&
          date.getTime() < end.getTime()
        ) {
          if (date.getTime() === start.getTime()) {
            if (
              this.fullCalendar.currentData.currentViewType === "timeGridDay"
            ) {
              return this.dateCurrentViewClass;
            } else {
              return [this.dateCurrentViewClass, "first"];
            }
          } else if (
            moment(date)
              .add(1, "days")
              .toDate()
              .getTime() === end.getTime()
          ) {
            if (
              this.fullCalendar.currentData.currentViewType === "timeGridDay"
            ) {
              return this.dateCurrentViewClass;
            } else {
              return [this.dateCurrentViewClass, "last"];
            }
          } else {
            return this.dateCurrentViewClass;
          }
        }

        return "";
      }
    },

    /**
     * Update monthString and yearString data properties on small calendar context
     * @param {Object} ctx
     * @return {void}
     */
    onContext(ctx) {
      let activeDate = ctx.activeYMD;
      this.monthString = moment(activeDate)
        .locale(this.locale)
        .format("MMMM");
      this.yearString = moment(activeDate).format("YYYY");
    },

    /**
     * Change full calendar view on small calendar date select
     * @param {String} ymd
     * @return {void}
     */
    onDateSelect(ymd) {
      if (this.fullCalendar) {
        this.fullCalendar.changeView(
          this.fullCalendar.currentData.currentViewType,
          ymd
        );
        this.emitChangeEvent();
      }
    },

    /**
     * Emit change event
     * @emit change
     */
    emitChangeEvent() {
      let periodObject = {
        from: moment(this.fullCalendar.currentData.viewApi.activeStart).format(
          "YYYY-MM-DD"
        ),
        to: moment(this.fullCalendar.currentData.viewApi.activeEnd).format(
          "YYYY-MM-DD"
        )
      };

      this.$emit("change", periodObject);
    }
  },
  computed: {
    /**
     * Return class name for date current view class
     * @return {String}
     */
    dateCurrentViewClass() {
      if (this.fullCalendar) {
        if (this.fullCalendar.currentData.currentViewType === "timeGridDay") {
          return "current-view single";
        }
        return "current-view";
      }
      return "";
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/sass/bb-styles/components/mobile-period-filter.scss";
</style>
