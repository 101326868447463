<template>
  <div
    id="mobile_place_staff_filter"
    ref="mobile_place_staff_filter"
    class="offcanvas offcanvas-right"
    v-click-outside="handleOutsideClick"
  >
    <button
      id="mobile_place_staff_filter_toggle"
      style="display: none;"
    ></button>
    <!--begin::Header-->
    <div
      class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
    >
      <h4 class="font-weight-bold m-0">
        {{ $t("calendarFilters.mobilePlaceAndStaffFilter.title") }}
      </h4>
      <a
        href="#"
        class=""
        id="mobile_place_staff_filter_close"
        @click="closeOffcanvas('mobile_place_staff_filter')"
      >
        <i class="ki ki-close icon-md"></i>
      </a>
    </div>
    <!--end::Header-->
    <!--begin::Content-->
    <perfect-scrollbar
      class="offcanvas-content mr-n5 scroll"
      style="max-height: 85vh; position: relative;"
    >
      <div class="d-flex justify-content-between align-items-center mb-7">
        <b-button
          v-b-toggle.collapse-places-filter
          class="m-1 bb-colapse mobile-places-filter-collapse"
          @click="placesIsOpen != placesIsOpen"
        >
          <div class="d-flex align-items-center">
            <i class="mr-7" :class="placesArrowClass"></i>
            <span>{{
              $t("calendarFilters.mobilePlaceAndStaffFilter.placesLabel")
            }}</span>
          </div>
        </b-button>
        <button
          class="pl-8"
          @click="createPlace()"
          v-if="
            $hasRole(acl.roles.owner) ||
              $hasPermission(acl.permissions.manageAccount) ||
              $hasPermission(acl.permissions.managePlace)
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-plus"
          >
            <line x1="12" y1="5" x2="12" y2="19"></line>
            <line x1="5" y1="12" x2="19" y2="12"></line>
          </svg>
        </button>
      </div>
      <b-collapse v-model="placesIsOpen" id="collapse-places-filter">
        <div class="places-container">
          <div
            class="place-item"
            v-for="place in allPlaces"
            :key="place.id"
            :class="place.id === currentPlace.id ? 'active' : ''"
            @click="setCurrentPlace(place)"
          >
            <i class="fas fa-store"></i>
            <span>{{ place.name }}</span>
          </div>
        </div>
      </b-collapse>
      <div class="d-flex justify-content-between align-items-center mt-7 mb-7">
        <b-button
          v-b-toggle.collapse-staff-filter
          class="m-1 bb-colapse mobile-staff-filter-collapse"
          @click="staffIsOpen != staffIsOpen"
        >
          <div class="d-flex align-items-center">
            <i class="mr-7" :class="staffArrowClass"></i>
            <span>{{
              $t("calendarFilters.mobilePlaceAndStaffFilter.staffLabel")
            }}</span>
          </div>
        </b-button>
        <button
          class="pl-8"
          @click="
            close();
            createCalendar();
          "
          v-if="
            $hasRole(acl.roles.owner) ||
              $hasPermission(acl.permissions.manageAccount) ||
              $hasPermission(acl.permissions.managePlace)
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-plus"
          >
            <line x1="12" y1="5" x2="12" y2="19"></line>
            <line x1="5" y1="12" x2="19" y2="12"></line>
          </svg>
        </button>
      </div>
      <b-collapse v-model="staffIsOpen" id="collapse-staff-filter">
        <div
          class="mobile-staff-filter-toggle-all"
          :class="allStaffSelected ? 'active' : ''"
        >
          <v-checkbox
            color="secondary"
            v-model="allStaffSelected"
            hide-details
          ></v-checkbox>
          <span>{{
            $t("calendarFilters.mobilePlaceAndStaffFilter.allStaffLabel")
          }}</span>
        </div>
        <div
          class="bb-staff-filter-item"
          v-for="staffMember in staff"
          :key="staffMember.id"
        >
          <div
            class="mobile-staff-filter-item"
            :class="staffMember.checked ? 'active' : ''"
          >
            <v-checkbox
              :color="staffMember.calendar.color"
              v-model="staffMember.checked"
              @click="toggleAllSelected"
              hide-details
            ></v-checkbox>
            <span>{{ staffMember.fullName }}</span>
          </div>
        </div>
      </b-collapse>
    </perfect-scrollbar>
    <!--end::Content-->
    <PlaceFormModal ref="placeFormModal" />
    <CalendarFormModal ref="calendarFormModal" />
  </div>
</template>

<script>
import MobilePlaceAndStaffFilterLayout from "@/assets/js/layout/extended/mobile-place-and-staff-filter.js";
import { CLOSE_ELEMENT } from "@/core/services/store/offcanvas.module.js";
import { mapGetters } from "vuex";
import { SET_PLACE } from "@/core/services/store/places.module.js";
import PlaceFormModal from "@/view/layout/extras/modal/PlaceFormModal.vue";
import placeFormMixin from "@/core/mixins/placeFormMixin.js";
// import calendarFormMixin from "@/core/mixins/calendarFormMixin.js";
import calendarFormModalMixin from "@/core/mixins/calendarFormModalMixin.js";
import CalendarFormModal from "@/view/layout/extras/modal/CalendarFormModal.vue";

export default {
  name: "MobilePlaceAndStaffFilter",
  mixins: [placeFormMixin, calendarFormModalMixin],
  components: { PlaceFormModal, CalendarFormModal },
  data() {
    return {
      placesIsOpen: true,
      staffIsOpen: true,
      allStaffSelected: true,
      staff: []
    };
  },
  mounted() {
    // Init Date Input Panel
    MobilePlaceAndStaffFilterLayout.init(
      this.$refs["mobile_place_staff_filter"]
    );
  },
  methods: {
    /**
     * Handle outside click
     * @return {void}
     */
    handleOutsideClick(event) {
      if (event.target.classList.contains("offcanvas-overlay")) {
        this.close();
      }
    },
    /**
     * Dispach CLOSE_ELEMENT action from offcanvas module
     * @return {void}
     */
    closeOffcanvas(elementId) {
      this.$store.dispatch(CLOSE_ELEMENT, elementId);
      if (elementId === "mobile_place_staff_filter") {
        this.$emit("closed");
      }
    },

    /**
     * Close mobile place and staff filter element
     * @return {void}
     */
    close() {
      document.querySelector("#mobile_place_staff_filter_close").click();
    },

    /**
     * Set selected place in store places module
     * @param {Object} place
     * @return {void}
     */
    setCurrentPlace(place) {
      this.$store.dispatch(SET_PLACE, place);
    },

    /**
     * Map staff for current place to staff data property
     * @return {void}
     */
    mapStaff() {
      this.staff = [];
      this.allStaffSelected = true;
      let staff = JSON.parse(JSON.stringify(this.currentPlace.staff));
      staff.forEach(staffMember => {
        staffMember.checked = true;
        this.staff.push(staffMember);
      });
    },

    /**
     * Toggle allStaffSelected data property
     * @return {void}
     */
    toggleAllSelected() {
      if (
        this.staff.filter(staffMember => staffMember.checked === false).length >
        0
      ) {
        this.allStaffSelected = false;
      } else {
        this.allStaffSelected = true;
      }
    }
  },
  computed: {
    ...mapGetters(["allPlaces", "currentPlace"]),

    /**
     * Return class for places filter arrow
     * @return {String}
     */
    placesArrowClass() {
      if (this.placesIsOpen) {
        return "la la-angle-up";
      }

      return "la la-angle-down";
    },

    /**
     * Return class for staff filter arrow
     * @return {String}
     */
    staffArrowClass() {
      if (this.staffIsOpen) {
        return "la la-angle-up";
      }

      return "la la-angle-down";
    }
  },
  watch: {
    /**
     * Watch for currentPlace change
     */
    currentPlace() {
      this.mapStaff();
    },
    /**
     * Watch for allStaffSelected change
     */
    allStaffSelected(allStaffSelected) {
      if (allStaffSelected) {
        this.staff.map(staffMember => (staffMember.checked = true));
      }
    },
    /**
     * Watch for staff change
     */
    staff: {
      immediate: true,
      deep: true,
      handler() {
        if (this.staff.length > 0) {
          let checkedCalendarIds = [];
          this.staff.forEach(staffMember => {
            if (staffMember.checked) {
              checkedCalendarIds.push(staffMember.calendar.id);
            }
          });
          this.$emit("change", checkedCalendarIds);
        }
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/sass/bb-styles/components/mobile-place-and-staff-filter.scss";
</style>
