<template>
  <div class="bb-view-filter">
    <b-form-group>
      <b-form-radio-group
        ref="switchView"
        id="bb_view_filter_toggle"
        v-model="selectedView"
        :options="calendarViews"
        buttons
        name="radios-btn-default"
        @change="switchView()"
      ></b-form-radio-group>
    </b-form-group>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "ViewFilter",
  props: {
    /**
     * Page's main calendar (full calendar plugin)
     * @type {Object}
     */
    fullCalendar: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      selectedView: "timeGridWeek",
      calendarViews: [
        {
          text: this.$t("calendarFilters.viewFilter.week"),
          value: "timeGridWeek"
        },
        {
          text: this.$t("calendarFilters.viewFilter.day"),
          value: "timeGridDay"
        }
      ]
    };
  },
  methods: {
    /**
     * Switch full calendar view
     * @return {void}
     */
    switchView() {
      if (this.fullCalendar) {
        setTimeout(() => {
          this.fullCalendar.changeView(this.selectedView);
          this.emitChangeEvent();
        }, 100);
      }
    },

    /**
     * Emit change event
     * @emit change
     */
    emitChangeEvent() {
      let periodObject = {
        from: moment(this.fullCalendar.currentData.viewApi.activeStart).format(
          "YYYY-MM-DD"
        ),
        to: moment(this.fullCalendar.currentData.viewApi.activeEnd).format(
          "YYYY-MM-DD"
        )
      };

      this.$emit("change", periodObject);
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/sass/bb-styles/components/view-filter.scss";
</style>
