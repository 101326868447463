<template>
  <div
    id="calendar_page_wrapper"
    style="position: relative"
    ref="calendarRoot"
    @touchstart="handleTouchStart"
    @touchend="handleTouchEnd"
  >
    <MobilePlaceAndStaffFilter
      @change="staffFilterChange($event)"
      @closed="handleMobilePlaceAndStaffFilterClosed()"
    />
    <div
      class="bb-mobile-header"
      :style="
        showNewEventActions ||
        selectedAppointment ||
        mobilePlaceAndStaffFilterOpened
          ? 'z-index: 90'
          : ''
      "
    >
      <div class="bb-mobile-header-left">
        <button class="mobile-sidebar-btn" @click="openMobileAside()">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-menu"
          >
            <line x1="3" y1="12" x2="21" y2="12"></line>
            <line x1="3" y1="6" x2="21" y2="6"></line>
            <line x1="3" y1="18" x2="21" y2="18"></line>
          </svg>
        </button>
        <MobilePeriodFilter
          :fullCalendar="calendar"
          @change="periodChanged($event)"
        />
      </div>
      <div class="bb-mobile-header-right">
        <MobileViewFilter
          :fullCalendar="calendar"
          @change="periodChanged($event)"
          @toggle="emitToggleOverlay()"
        />
        <button class="ml-7" @click="openMobilePlaceAndStaffFilter()">
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.0642 0H3.93577C1.76559 0 0 1.76559 0 3.93577V18.0643C0 20.2344 1.76559 22 3.93577 22H18.0643C20.2344 22 22 20.2344 22 18.0642V3.93577C22 1.76559 20.2344 0 18.0642 0ZM20.7109 18.0642C20.7109 19.5236 19.5236 20.7109 18.0642 20.7109H3.93577C2.47638 20.7109 1.28906 19.5236 1.28906 18.0642V3.93577C1.28906 2.47638 2.47638 1.28906 3.93577 1.28906H18.0643C19.5236 1.28906 20.7109 2.47638 20.7109 3.93577V18.0642Z"
              fill="#253342"
            />
            <path
              d="M18.5942 4.83262H8.77756C8.50767 4.03104 7.74949 3.4519 6.85806 3.4519C5.96663 3.4519 5.20845 4.03104 4.93856 4.83262H3.40625C3.0503 4.83262 2.76172 5.1212 2.76172 5.47715C2.76172 5.8331 3.0503 6.12168 3.40625 6.12168H4.9386C5.20849 6.92326 5.96667 7.5024 6.8581 7.5024C7.74953 7.5024 8.50772 6.92326 8.7776 6.12168H18.5943C18.9502 6.12168 19.2388 5.8331 19.2388 5.47715C19.2388 5.1212 18.9502 4.83262 18.5942 4.83262ZM6.85806 6.21333C6.45213 6.21333 6.12188 5.88308 6.12188 5.47715C6.12188 5.07122 6.45213 4.74097 6.85806 4.74097C7.26399 4.74097 7.59424 5.07122 7.59424 5.47715C7.59424 5.88308 7.26399 6.21333 6.85806 6.21333Z"
              fill="#253342"
            />
            <path
              d="M18.5942 10.3556H17.0619C16.792 9.55399 16.0338 8.97485 15.1424 8.97485C14.251 8.97485 13.4928 9.55399 13.2229 10.3556H3.40625C3.0503 10.3556 2.76172 10.6441 2.76172 11.0001C2.76172 11.3561 3.0503 11.6446 3.40625 11.6446H13.2229C13.4928 12.4462 14.251 13.0253 15.1424 13.0253C16.0338 13.0253 16.792 12.4462 17.0619 11.6446H18.5943C18.9502 11.6446 19.2388 11.3561 19.2388 11.0001C19.2388 10.6441 18.9502 10.3556 18.5942 10.3556ZM15.1424 11.7363C14.7365 11.7363 14.4063 11.406 14.4063 11.0001C14.4063 10.5942 14.7365 10.2639 15.1424 10.2639C15.5484 10.2639 15.8786 10.5942 15.8786 11.0001C15.8786 11.406 15.5484 11.7363 15.1424 11.7363Z"
              fill="#253342"
            />
            <path
              d="M18.5942 15.8784H11.539C11.2691 15.0768 10.511 14.4977 9.61953 14.4977C8.7281 14.4977 7.96992 15.0768 7.70003 15.8784H3.40625C3.0503 15.8784 2.76172 16.167 2.76172 16.5229C2.76172 16.8789 3.0503 17.1675 3.40625 17.1675H7.70003C7.96992 17.969 8.7281 18.5482 9.61953 18.5482C10.511 18.5482 11.2691 17.969 11.539 17.1675H18.5943C18.9502 17.1675 19.2388 16.8789 19.2388 16.5229C19.2388 16.167 18.9502 15.8784 18.5942 15.8784ZM9.61953 17.2592C9.21361 17.2592 8.88335 16.9289 8.88335 16.523C8.88335 16.117 9.21361 15.7868 9.61953 15.7868C10.0255 15.7868 10.3557 16.117 10.3557 16.5229C10.3557 16.9289 10.0255 17.2592 9.61953 17.2592Z"
              fill="#253342"
            />
          </svg>
        </button>
      </div>
    </div>
    <b-alert
      :show="alertVisible"
      dismissible
      class="calendar-alert d-flex"
      @dismissed="handleAlertDismiss()"
    >
      <img :src="alertImagePath" />
      <div class="alert-text">
        <h2>{{ $t("calendar.alert.title") }}</h2>
        <p>{{ $t("calendar.alert.text") }}</p>
      </div>
    </b-alert>
    <div
      class="row d-flex justify-content-between mb-5 pr-4 pl-4 calendar-filters"
    >
      <PlaceFilter />
      <StaffFilter @change="staffFilterChange($event)" />
      <PeriodFilter
        :fullCalendar="calendar"
        :periodTitle="currentCalendarTitle"
        @change="periodChanged($event)"
      />
      <ViewFilter :fullCalendar="calendar" @change="periodChanged($event)" />
    </div>
    <FullCalendar
      ref="fullCalendar"
      :options="calendarOptions"
      v-click-outside="hideAllEventPopovers"
    />
    <div
      class="bb-new-event-container"
      :class="newEventButtonActiveClass"
      v-click-outside="handleNewEventOutsideClick"
    >
      <div class="bb-new-event-actions" v-show="showNewEventActions">
        <button
          class="btn"
          @click="
            createAppointment(newEventData);
            toggleNewEventActions();
          "
        >
          <i v-show="newEventButtonsShowIcon" class="far fa-calendar"></i
          >{{ newAppointmentButtonText }}
        </button>
        <button
          class="btn"
          @click="
            createAppointment({ ...newEventData, type: 'GROUP' });
            toggleNewEventActions();
          "
        >
          <i v-show="newEventButtonsShowIcon" class="fa fa-users"></i
          >{{ $t('calendar.newEventActions.groupAppointment') }}
        </button>
        <button
          class="btn"
          @click="
          createBlockedTime(newEventData);
            toggleNewEventActions();
          "
        >
          <i v-show="newEventButtonsShowIcon" class="far fa-clock"></i>
          {{ $t("calendar.newEventActions.blockTime") }}
        </button>
      </div>
      <button
        class="btn bb-new-event-btn"
        :class="newEventButtonActiveClass"
        @click="toggleNewEventActions"
        v-if="showNewEventButton"
        v-html="newEventToggleButtonHtml"
      >
        <!-- <i :class="newEventIconClass"></i> -->
      </button>
    </div>
    <MobileEventPopover
      :appointment="selectedAppointment"
      ref="mobileEventPopover"
      v-click-outside="handleMobileEventPopoverClickOutside"
      @editAppointment="editAppointment($event)"
      @cancelAppointment="cancelAppointment($event)"
      @editBlockTime="editBlockedTime($event)"
      @removeBlockTime="deleteAppointment($event)"
    />
    <CancelAppointmentModal ref="cancelAppointmentModal" />
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import { mapGetters } from "vuex";
import { GET_PLACES } from "@/core/services/store/places.module.js";
import PlaceFilter from "@/view/pages/components/Calendar/PlaceFilter.vue";
import StaffFilter from "@/view/pages/components/Calendar/StaffFilter.vue";
import PeriodFilter from "@/view/pages/components/Calendar/PeriodFilter.vue";
import ViewFilter from "@/view/pages/components/Calendar/ViewFilter.vue";
import appointmentFormMixin from "@/core/mixins/appointmentFormMixin.js";
import blockTimeFormMixin from "@/core/mixins/blockTimeFormMixin.js";
import { GET_SEARCH_ENGINE_TYPES } from "@/core/services/store/searchEngineTypes.module";
import moment from "moment";
import httpService from "@/core/services/http.service.js";
import appointmentMapper from "@/core/services/helpers/appointmentMapper.js";
import { SET_ALERT_MESSAGES } from "@/core/services/store/alert.module.js";
import _ from "lodash";
import CancelAppointmentModal from "@/view/layout/extras/modal/CancelAppointmentModal.vue";
import cancelAppointmentMixin from "@/core/mixins/cancelAppointmentMixin.js";
import mobileAsideMixin from "@/core/mixins/mobileAsideMixin.js";
import MobileViewFilter from "@/view/pages/components/Calendar/MobileViewFilter.vue";
import MobilePeriodFilter from "@/view/pages/components/Calendar/MobilePeriodFilter.vue";
import MobilePlaceAndStaffFilter from "@/view/pages/components/Calendar/MobilePlaceAndStaffFilter.vue";
import MobileEventPopover from "@/view/pages/components/Calendar/MobileEventPopover.vue";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import i18nService from "@/core/services/i18n.service.js";

export default {
  name: "Calendar",
  components: {
    FullCalendar,
    PlaceFilter,
    StaffFilter,
    PeriodFilter,
    ViewFilter,
    CancelAppointmentModal,
    MobileViewFilter,
    MobilePeriodFilter,
    MobilePlaceAndStaffFilter,
    MobileEventPopover,
  },
  mixins: [
    appointmentFormMixin,
    blockTimeFormMixin,
    cancelAppointmentMixin,
    mobileAsideMixin,
  ],
  data() {
    return {
      touchstartX: 0,
      touchstartY: 0,
      touchendX: 0,
      touchendY: 0,
      selectedAppointment: null,
      calendar: null,
      showNewEventActions: false,
      showNewEventButton: true,
      mobilePlaceAndStaffFilterOpened: false,
      newEventData: {
        date: new Date(),
        from: null,
      },
      filters: {
        placeId: null,
        calendarIds: [],
        from: null,
        to: null,
      },
      calendarOptions: {
        locale: i18nService.getActiveLanguage(),
        height: "1000px",
        windowResize: function () {
          if (window.innerWidth < 992) {
            if (this.currentData.viewApi.type === "timeGridWeek") {
              this.changeView("timeGridThreeDay");
            }
          } else {
            if (this.currentData.viewApi.type === "timeGridThreeDay") {
              this.changeView("timeGridWeek");
            }
          }
        },
        events: [],
        eventTimeFormat: {
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        },
        selectable: true,
        selectLongPressDelay: 500,
        select: this.handleCalendarSelect,
        selectAllow: this.handleCalendarSelectAllow,
        eventDidMount: this.handleEventDidMount,
        eventClick: this.handleEventClick,
        dateClick: this.handleDateClick,
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
        initialView:
          window.innerWidth < 992 ? "timeGridThreeDay" : "timeGridWeek",
        allDaySlot: false,
        headerToolbar: false,
        slotMinTime: "00:00",
        slotMaxTime: "24:00",
        firstDay: 1,
        businessHours: [],
        slotLabelFormat: {
          hour: "2-digit",
          minute: "2-digit",
          omitZeroMinute: false,
          meridiem: "long",
          hour12: false,
        },
        scrollTime: "10:00",
        views: {
          timeGridWeek: {
            dayHeaderFormat: {
              weekday: "short",
              day: "numeric",
            },
            dayHeaderContent: function (arg) {
              let textArray = arg.text.split(" ");
              let dateNum = "";
              let weekDay = "";
              if (i18nService.getActiveLanguage() === "en") {
                dateNum = textArray[0];
                weekDay = textArray[1];
              } else {
                dateNum = textArray[1];
                weekDay = textArray[0];
              }
              let todayClass = "";
              if (arg.isToday) {
                todayClass = " today";
              }
              return {
                html:
                  `
                <div class="fc-head-week-day` +
                  todayClass +
                  `"> ` +
                  weekDay +
                  ` </div>
                <div class="fc-head-date-num` +
                  todayClass +
                  `"> ` +
                  dateNum +
                  ` </div>
              `,
              };
            },
          },
          timeGridDay: {
            dayHeaderFormat: {
              weekday: "long",
              day: "numeric",
            },
            dayHeaderContent: function (arg) {
              let textArray = arg.text.split(" ");
              let dateNum = "";
              let weekDay = "";
              if (i18nService.getActiveLanguage() === "en") {
                dateNum = textArray[0];
                weekDay = textArray[1];
              } else {
                dateNum = textArray[1];
                weekDay = textArray[0];
              }
              return {
                html:
                  `
                <div class="fc-head-week-day"> ` +
                  weekDay +
                  ` </div>
                <div class="fc-head-date-num"> ` +
                  dateNum +
                  ` </div>
              `,
              };
            },
          },
          timeGridThreeDay: {
            type: "timeGrid",
            duration: { days: 3 },
            dayHeaderFormat: {
              weekday: "short",
              day: "numeric",
            },
            dayHeaderContent: function (arg) {
              let textArray = arg.text.split(" ");
              let dateNum = "";
              let weekDay = "";
              if (i18nService.getActiveLanguage() === "en") {
                dateNum = textArray[0];
                weekDay = textArray[1];
              } else {
                dateNum = textArray[1];
                weekDay = textArray[0];
              }
              let todayClass = "";
              if (arg.isToday) {
                todayClass = " today";
              }
              return {
                html:
                  `
                <div class="fc-head-week-day` +
                  todayClass +
                  `"> ` +
                  weekDay +
                  ` </div>
                <div class="fc-head-date-num` +
                  todayClass +
                  `"> ` +
                  dateNum +
                  ` </div>
              `,
              };
            },
          },
        },
        nowIndicator: true,
      },
    };
  },
  mounted() {
    // Dispatch store SET_BREADCRUMB action to set page title
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("calendar.title") },
    ]);
    // Dispatch store GET_PLACES action to get places from api
    this.$store.dispatch(GET_PLACES);
    // Set calendar by fullCalendar ref
    this.calendar = this.$refs.fullCalendar.$options.calendar;
    // Set component filters
    this.filters.from = moment(
      this.calendar.currentData.viewApi.activeStart
    ).format("YYYY-MM-DD");
    this.filters.to = moment(
      this.calendar.currentData.viewApi.activeEnd
    ).format("YYYY-MM-DD");
    // Dispatch store GET_SEARCH_ENGINE_TYPES action to get search engine types from api
    this.$store.dispatch(GET_SEARCH_ENGINE_TYPES);
  },
  methods: {
    /**
     * Handle touch start event on page
     * @param {Object} event
     * @return {void}
     */
    handleTouchStart(event) {
      this.touchstartX = event.changedTouches[0].screenX;
      this.touchstartY = event.changedTouches[0].screenY;
    },

    /**
     * Handle touch end event on page
     * @param {Object} event
     * @return {void}
     */
    handleTouchEnd(event) {
      this.touchendX = event.changedTouches[0].screenX;
      this.touchendY = event.changedTouches[0].screenY;
      if (!this.selectedAppointment) {
        let start = new Date(
          this.calendar.currentData.dateProfile.currentRange.start
        );
        let end = new Date(
          this.calendar.currentData.dateProfile.currentRange.end
        );

        if (
          this.touchendX <= this.touchstartX &&
          this.touchstartX - this.touchendX > 100
        ) {
          start.setDate(start.getDate() + 3);
          end.setDate(end.getDate() + 3);
          let swipeEventObject = {
            from: start.toISOString().split("T")[0],
            to: end.toISOString().split("T")[0],
          };

          this.periodChanged(swipeEventObject);
          this.calendar.next();
        }

        if (
          this.touchendX >= this.touchstartX &&
          this.touchendX - this.touchstartX > 100
        ) {
          start.setDate(start.getDate() - 3);
          end.setDate(end.getDate() - 3);
          let swipeEventObject = {
            from: start.toISOString().split("T")[0],
            to: end.toISOString().split("T")[0],
          };

          this.periodChanged(swipeEventObject);
          this.calendar.prev();
        }
      }
    },

    /**
     * Handle the main page alert dismissed
     * @return {void}
     */
    handleAlertDismiss() {
      window.localStorage.setItem("alert-" + this.currentUser.id, "true");
    },

    /**
     * Handle new event container buttons outside click
     * @param {Object} event
     * @return {void}
     */
    handleNewEventOutsideClick(event) {
      if (
        event.target.classList.contains("offcanvas-overlay") &&
        this.showNewEventActions
      ) {
        this.toggleNewEventActions();
      }
    },

    /**
     * Handle calendar's eventDidMount hook
     * @param {Object} info
     * @return {void}
     */
    handleEventDidMount(info) {
      let el = "";
      if (info.event._def.extendedProps.appointment.type === "BLOCK_TIME") {
        el = this.getBlockTimePopoverString(info);
      } else {
        el = this.getAppointmentPopoverString(info);
      }
      info.el.insertAdjacentHTML("beforeend", el);
    },

    /**
     * Check if current user can manage appointment
     * @param {Object} appointment
     * @return {Boolean}
     */
    canManageAppointment(appointment) {
      if (
        this.$hasRole(this.acl.roles.staffMember) &&
        this.$hasOnlyBasePermission()
      ) {
        return appointment?.employee?.id == this.currentUser.id;
      }
      return true;
    },

    /**
     * Return html string for appointment popover
     * @param {Object} info
     * @return {String}
     */
    getAppointmentPopoverString(info) {
      let positionClass = this.getElementPositionClass(info.el, info.view.type);
      let appointmentNotes = info.event._def.extendedProps.appointment.notes
        ? info.event._def.extendedProps.appointment.notes
        : this.$t("calendar.appointment.noNotesText");
      let eventContacts = ``;
      if (info.event._def.extendedProps.appointment.client) {
        if (
          this.canManageAppointment(info.event._def.extendedProps.appointment)
        ) {
          if (info.event._def.extendedProps.appointment.client.phone) {
            eventContacts +=
              `
                            <a href="tel:` +
              info.event._def.extendedProps.appointment.client.phone +
              `">
                              <i class="fas fa-phone-alt"></i>
                            </a>
                          `;
          }
          if (info.event._def.extendedProps.appointment.client.email) {
            eventContacts +=
              `
                            <a href="mailto:` +
              info.event._def.extendedProps.appointment.client.email +
              `">
                              <i class="flaticon2-black-back-closed-envelope-shape"></i>
                            </a>
                          `;
          }
        }
      }

      let deletedService = ``;
      if (info.event._def.extendedProps.appointment.service.deleted == false) {
        deletedService =
          `<span class="event-service">` +
          info.event._def.extendedProps.appointment.service.name +
          `</span>`;
      } else if (
        info.event._def.extendedProps.appointment.service.deleted == true
      ) {
        deletedService =
          `<span class="event-service" style="color:#F64E60">` +
          info.event._def.extendedProps.appointment.service.name +
          `</span>`;
      }

      let subjectInfo = ``;
      if(this.currentUser.isInLawIndustry) {
        subjectInfo = 
        `<span class="event-client">` +
          info.event._def.extendedProps.appointment.subject.subjectNumber +
        `</span>
        <span class="event-service">` +
          info.event._def.extendedProps.appointment.subject.courtName +
          ` - ` + 
          info.event._def.extendedProps.appointment.subject.courtNumber +
        `</span>`;
      }

      let popoverString =
        `
            <div class="bb-event-popover hidden ` +
        positionClass +
        `">
              <div class="d-flex justify-content-between align-items-center">
                <div class="event-popover-details">
                  <span class="event-time">` +
        info.timeText +
        `</span>` +
        subjectInfo +
        `<span class="event-client">` +
        info.event._def.title +
        `</span>` +
        deletedService +
        `<span class="event-client">` +
        this.$t("calendar.appointment.employee") +
        `</span>
                  <span class="event-service">` +
        info.event._def.extendedProps.appointment.employee.first_name +
        ` ` +
        info.event._def.extendedProps.appointment.employee.last_name +
        `</span>
                </div>
                <div class="event-contact-buttons">
                  ` +
        eventContacts +
        `
                </div>
              </div>
          `;

      if (
        this.canManageAppointment(info.event._def.extendedProps.appointment)
      ) {
        popoverString +=
          `
            <div class="event-notes">
              <span class="event-notes-label">` +
          this.$t("calendar.appointment.notesLabel") +
          `: </span>
              <span class="event-notes-text">` +
          appointmentNotes +
          `</span>
            </div>
            <div class="event-action-buttons">
              <button class="event-cancel-btn">` +
          this.$t("calendar.appointment.cancelAppointment") +
          `</button>
                <button class="event-edit-btn">` +
          this.$t("calendar.appointment.editAppointment") +
          `</button>
            </div>
          `;
      }

      popoverString += `</div>`;

      return popoverString;
    },

    /**
     * Return html string for block time popover
     * @param {Object} info
     * @return {String}
     */
    getBlockTimePopoverString(info) {
      let positionClass = this.getElementPositionClass(info.el, info.view.type);

      let popoverString =
        `
            <div class="bb-event-popover hidden ` +
        positionClass +
        `">
              <div class="d-flex justify-content-between align-items-center">
                <div class="event-popover-details">
                  <span class="event-time">` +
        info.timeText +
        `</span>
                  <span class="event-client">` +
        info.event._def.title +
        `</span>
                </div>
              </div>
              <div class="event-action-buttons">
                  <button class="event-cancel-btn">` +
        this.$t("calendar.blockTime.removeBlockTime") +
        `</button>
                  <button class="event-edit-btn">` +
        this.$t("calendar.blockTime.editBlockTime") +
        `</button>
              </div>
            </div>
          `;

      return popoverString;
    },

    /**
     * Handle click outside of mobile event popover elementhandleEventClick
     * @return {void}
     */
    handleMobileEventPopoverClickOutside() {
      if (this.$refs.mobileEventPopover) {
        if (this.$refs.mobileEventPopover.isOpen) {
          this.$refs.mobileEventPopover.close();
          this.emitToggleOverlay();
          this.selectedAppointment = null;
        }
      }
    },

    /**
     * Handle calendar's eventClick hook
     * @param {Object} info
     * @return {void}
     */
    handleEventClick(info) {
      if (window.innerWidth < 992) {
        this.selectedAppointment = info.event._def.extendedProps.appointment;
        // if (this.selectedAppointment.type === "REGULAR") {
        //   this.selectedAppointment.client = this.$store.getters.getClient(
        //     this.selectedAppointment.client.id
        //   );
        // }
        setTimeout(() => {
          this.$refs.mobileEventPopover.open();
          this.emitToggleOverlay();
        }, 100);
      } else {
        if (info.jsEvent.target.classList.value.includes("fc")) {
          info.el.childNodes.forEach((element) => {
            if (element && element.classList) {
              if (element.classList.contains("bb-event-popover")) {
                if (element.classList.contains("hidden")) {
                  this.hideAllEventPopovers();
                  element.classList.remove("hidden");
                  if (
                    parseInt(element.parentElement.parentElement.style.zIndex) <
                    1000
                  ) {
                    this.addZindex(element);
                  }
                } else {
                  element.classList.add("hidden");
                  if (
                    parseInt(element.parentElement.parentElement.style.zIndex) >
                    1000
                  ) {
                    this.removeZindex(element);
                  }
                }
                return false;
              }
            }
          });
        } else {
          if (info.jsEvent.target.classList.contains("event-cancel-btn")) {
            if (
              info.event._def.extendedProps.appointment.type === "BLOCK_TIME"
            ) {
              this.deleteAppointment(info.event._def.extendedProps.appointment);
            } else {
              this.cancelAppointment(info.event._def.extendedProps.appointment);
            }
          } else if (info.jsEvent.target.classList.contains("event-edit-btn")) {
            if (
              info.event._def.extendedProps.appointment.type === "BLOCK_TIME"
            ) {
              this.editBlockedTime(info.event._def.extendedProps.appointment);
            } else {
              this.editAppointment(info.event._def.extendedProps.appointment);
            }
          }
        }
      }
    },

    /**
     * Delete appointment
     * @param {Object} appointment
     * @return {void}
     */
    deleteAppointment(appointment) {
      httpService
        .delete("/api/b2b/appointments/" + appointment.id)
        .then((response) => {
          this.$store.dispatch(SET_ALERT_MESSAGES, {
            successMessages: [response.data.message],
            errorMessages: null,
          });
          this.$store.dispatch(GET_PLACES);
          if (this.$refs.mobileEventPopover) {
            if (this.$refs.mobileEventPopover.isOpen) {
              this.$refs.mobileEventPopover.close();
              this.emitToggleOverlay();
              this.selectedAppointment = null;
            }
          }
        })
        .catch((error) => {
          this.$store.dispatch(SET_ALERT_MESSAGES, {
            successMessages: null,
            errorMessages: [error.response.data.message],
          });
        });
    },

    /**
     * Handle calendar date click
     * @return {void}
     */
    handleDateClick() {
      this.hideAllEventPopovers();
    },

    /**
     * Handle calendar date select
     * @param {Object} selectionInfo
     * @return {void}
     */
    handleCalendarSelect(selectionInfo) {
      this.newEventData.date = selectionInfo.start
      this.newEventData.from = moment(selectionInfo.start).format("HH:mm")
      this.toggleNewEventActions()
    },

    /**
     * Determine if user can select specific date and time
     * @param {Object} selectionInfo
     * @return {Boolean}
     */
    handleCalendarSelectAllow(selectionInfo) {
      if (moment(selectionInfo.start).isBefore(moment())) {
        return false;
      }
      let day = moment(selectionInfo.start).day();
      let dayWorkingHours = null;
      this.calendarOptions.businessHours.forEach(function (businessHours) {
        if (day === businessHours.daysOfWeek[0]) {
          dayWorkingHours = {
            from: businessHours.startTime,
            to: businessHours.endTime,
          };
        }
      });
      if (dayWorkingHours) {
        let format = "HH:mm";
        let startTime = moment(selectionInfo.start).format(format);
        let from = dayWorkingHours.from;
        let to = dayWorkingHours.to;

        if (startTime === from) {
          return true;
        }

        if (
          moment(startTime, format).isBetween(
            moment(from, format),
            moment(to, format)
          )
        ) {
          return true;
        }

        return false;
      }
      return false;
    },

    /**
     * Hide all visible popovers
     * @return {void}
     */
    hideAllEventPopovers() {
      let allEventPopovers = document.querySelectorAll(".bb-event-popover");
      allEventPopovers.forEach((popoverEl) => {
        if (!popoverEl.classList.contains("hidden")) {
          popoverEl.classList.add("hidden");
          if (
            parseInt(popoverEl.parentElement.parentElement.style.zIndex) > 1000
          ) {
            this.removeZindex(popoverEl);
          }
        }
      });
    },

    /**
     * Add z-index to element
     * @param {Object} element
     * @return {void}
     */
    addZindex(element) {
      let colEl = element.parentElement;
      while (!colEl.classList.contains("fc-timegrid-col")) {
        colEl = colEl.parentElement;
      }
      colEl.style.zIndex = 100;
      element.parentElement.parentElement.style.zIndex =
        parseInt(element.parentElement.parentElement.style.zIndex) + 1000;
    },

    /**
     * Remove z-index from element
     * @param {Object} element
     * @return {void}
     */
    removeZindex(element) {
      let colEl = element.parentElement;
      while (!colEl.classList.contains("fc-timegrid-col")) {
        colEl = colEl.parentElement;
      }
      colEl.style.zIndex = "";
      element.parentElement.parentElement.style.zIndex =
        parseInt(element.parentElement.parentElement.style.zIndex) - 1000;
    },

    /**
     * Return class name for event element position
     * @param {Object} element
     * @param {String} calendarView
     * @return {String}
     */
    getElementPositionClass(element, calendarView) {
      let calendarPosition =
        this.$refs.fullCalendar.$el.getBoundingClientRect();
      let positionClass = "";
      if (calendarView === "timeGridWeek") {
        if (
          calendarPosition.right - element.getBoundingClientRect().right <
          375
        ) {
          positionClass += "left";
        } else {
          positionClass += "right";
        }

        if (
          element.getBoundingClientRect().bottom +
            document.documentElement.scrollTop +
            250 >
          calendarPosition.top + calendarPosition.height
        ) {
          positionClass += "-bottom";
        } else {
          positionClass += "-top";
        }
      }

      if (calendarView === "timeGridDay") {
        if (
          element.getBoundingClientRect().bottom +
            document.documentElement.scrollTop +
            250 >
          calendarPosition.top + calendarPosition.height
        ) {
          positionClass += "top";
        } else {
          positionClass += "bottom";
        }
      }

      return positionClass;
    },

    /**
     * Set calendars slotMinTime and slotMaxTime values
     * @return {void}
     */
    setCalendarBusinessHours() {
      this.calendarOptions.businessHours = [];
      let openingHours = this.currentPlace.openingHours;
      let minTime = null;
      let maxTime = null;
      let firstIteration = true;
      Object.keys(openingHours).forEach((key) => {
        this.setDayBusinessHours(key, openingHours[key]);
        if (openingHours[key].from) {
          if (firstIteration) {
            minTime = openingHours[key].from;
            maxTime = openingHours[key].to;
            firstIteration = false;
          } else {
            if (parseInt(openingHours[key].from) < parseInt(minTime)) {
              minTime = openingHours[key].from;
            }
            if (parseInt(openingHours[key].to) > parseInt(maxTime)) {
              maxTime = openingHours[key].to;
            }
          }
        }
      });
      // this.calendarOptions.slotMinTime = minTime;
      // this.calendarOptions.slotMaxTime = maxTime;
    },

    /**
     * Toggle showNewEventActions data property
     * @emits toggle-overlay
     */
    toggleNewEventActions() {
      this.showNewEventActions = !this.showNewEventActions;
      let targetElement = document.querySelector(".bb-new-event-actions");
      if (this.showNewEventActions) {
        disableBodyScroll(targetElement);
      } else {
        enableBodyScroll(targetElement);
      }
      this.emitToggleOverlay();
    },

    /**
     * Emit toggle overlay event
     * @emits toggle-overlay
     */
    emitToggleOverlay() {
      this.$emit("toggle-overlay");
    },

    /**
     * Set calendars business hours for each day
     * @return {void}
     */
    setDayBusinessHours(dayName, dayBusinessHours) {
      switch (dayName.toLowerCase()) {
        case "monday":
          if (dayBusinessHours.from) {
            this.calendarOptions.businessHours.push({
              daysOfWeek: [1],
              startTime: dayBusinessHours.from,
              endTime: dayBusinessHours.to,
            });
          }
          break;
        case "tuesday":
          if (dayBusinessHours.from) {
            this.calendarOptions.businessHours.push({
              daysOfWeek: [2],
              startTime: dayBusinessHours.from,
              endTime: dayBusinessHours.to,
            });
          }
          break;
        case "wednesday":
          if (dayBusinessHours.from) {
            this.calendarOptions.businessHours.push({
              daysOfWeek: [3],
              startTime: dayBusinessHours.from,
              endTime: dayBusinessHours.to,
            });
          }
          break;
        case "thursday":
          if (dayBusinessHours.from) {
            this.calendarOptions.businessHours.push({
              daysOfWeek: [4],
              startTime: dayBusinessHours.from,
              endTime: dayBusinessHours.to,
            });
          }
          break;
        case "friday":
          if (dayBusinessHours.from) {
            this.calendarOptions.businessHours.push({
              daysOfWeek: [5],
              startTime: dayBusinessHours.from,
              endTime: dayBusinessHours.to,
            });
          }
          break;
        case "saturday":
          if (dayBusinessHours.from) {
            this.calendarOptions.businessHours.push({
              daysOfWeek: [6],
              startTime: dayBusinessHours.from,
              endTime: dayBusinessHours.to,
            });
          }
          break;
        case "sunday":
          if (dayBusinessHours.from) {
            this.calendarOptions.businessHours.push({
              daysOfWeek: [0],
              startTime: dayBusinessHours.from,
              endTime: dayBusinessHours.to,
            });
          }
          break;
      }
    },

    /**
     * Open mobile place and staff filter by imitating click on mobile_place_staff_filter_toggle element
     * @return {void}
     */
    openMobilePlaceAndStaffFilter() {
      document.querySelector("#mobile_place_staff_filter_toggle").click();
      let targetElement = document.querySelector("#mobile_place_staff_filter");
      disableBodyScroll(targetElement);
      this.mobilePlaceAndStaffFilterOpened = true;
    },

    /**
     * Handle mobile place and staff filter closed event
     * @return {void}
     */
    handleMobilePlaceAndStaffFilterClosed() {
      this.mobilePlaceAndStaffFilterOpened = false;
      let targetElement = document.querySelector("#mobile_place_staff_filter");
      enableBodyScroll(targetElement);
    },

    /**
     * Handle staff filter change
     * @param {Object} event
     * @return {void}
     */
    staffFilterChange(event) {
      this.filters.calendarIds = event;
    },

    /**
     * Handle period and view filter change
     * @param {Object} event
     * @return {void}
     */
    periodChanged(event) {
      this.filters.from = event.from;
      this.filters.to = event.to;
    },

    /**
     * Check if none of filters is null or undefined
     * @return {Boolean}
     */
    filtersReady() {
      return (
        this.filters.placeId &&
        this.filters.calendarIds.length > 0 &&
        this.filters.from &&
        this.filters.to
      );
    },

    /**
     * Filter calendar events by call api
     * @return {void}
     */
    filterCalendarEvents() {
      httpService
        .get("/api/b2b/appointments", {
          params: _.mapKeys(this.filters, (value, key) => _.snakeCase(key)),
        })
        .then((response) => {
          let appointments = appointmentMapper.mapAppointments(
            response.data.data
          );
          this.updateCalendarEvents(appointments);
        })
        .catch((error) => {
          this.$store.dispatch(SET_ALERT_MESSAGES, {
            successMessages: null,
            errorMessages: [error.response.data.message],
          });
        });
    },

    /**
     * Update calendar events
     * @param {Array} appointments
     * @return {void}
     */
    updateCalendarEvents(appointments) {
      this.calendarOptions.events = [];
      appointments.forEach((appointment) => {
        let color = this.getEventColor(appointment);
        let title = "";
        if (appointment.type === "BLOCK_TIME") {
          title = appointment.notes;
        } else {
          if(appointment.type === "REGULAR") {
            title = appointment.client?.fullName ?? "Unknown";
          }
          if(appointment.type === "GROUP") { 
            title = appointment.clients ? 
              `${appointment.clients.length}/${appointment.service?.sameTimePeople ?? '*'} ${this.$t("calendar.calendarEventCard.clientsLabel")}` :
              "Unknown";
          }
        }
        let eventObject = {
          id: appointment.id,
          title: title,
          start: new Date(appointment.date + "T" + appointment.from),
          end: new Date(appointment.date + "T" + appointment.to),
          backgroundColor: color + "50",
          textColor: color,
          borderColor: color,
          extendedProps: {
            appointment: appointment,
            // previousAppointments: this.$store.getters.getClient(
            //   appointment.client.id
            // ).appointments
          },
        };
        this.calendarOptions.events.push(eventObject);
      });
    },

    /**
     * Get the event color from appointment employee
     * @param {Object} appointment
     * @return {String}
     */
    getEventColor(appointment) {
      let color = "#F0000";
      if (appointment.calendarId) {
        let employee = null;
        this.currentPlace.staff.forEach((staffMember) => {
          if (staffMember.calendar.id === appointment.calendarId) {
            employee = staffMember;
            return false;
          }
        });
        if (employee) {
          color = employee.calendar.color;
        }
      }
      return color;
    },
  },
  computed: {
    ...mapGetters([
      "currentPlace",
      "isOffcanvasOpen",
      "allPlaces",
      "currentUser",
    ]),

    /**
     * Check if new event buttons can show icons
     * @return {Boolean}
     */
    newEventButtonsShowIcon() {
      if (window.innerWidth < 992) {
        return false;
      }

      return true;
    },

    /**
     * Return string for new appointment button
     * @return {String}
     */
    newAppointmentButtonText() {
      if (window.innerWidth < 992) {
        return this.$t("calendar.newEventActions.appointmentMobile");
      }

      return this.$t("calendar.newEventActions.appointment");
    },

    /**
     * Return html string for new event toggle button
     * @return {String}
     */
    newEventToggleButtonHtml() {
      if (this.showNewEventActions) {
        if (window.innerWidth < 992) {
          return (
            `<span>` + this.$t("calendar.newEventActions.close") + `</span>`
          );
        }

        return `<i class="` + this.newEventIconClass + `"></i>`;
      }

      return `<i class="` + this.newEventIconClass + `"></i>`;
    },

    /**
     * Check if alert should be visible
     * @return {Boolean}
     */
    alertVisible() {
      let notVisible = window.localStorage.getItem(
        "alert-" + this.currentUser.id
      );
      if (notVisible) {
        return false;
      }
      return true;
    },

    /**
     * Return path for alert image
     * @return {String}
     */
    alertImagePath() {
      return process.env.BASE_URL + "media/bg/calendar-alert-image.png";
    },

    /**
     * Return current view title from full calendar plugin object
     * @return {String}
     */
    currentCalendarTitle() {
      return this.calendar ? this.calendar.currentData.viewTitle : null;
    },

    /**
     * Return active class if showNewEventActions data property is true
     * @return {String}
     */
    newEventButtonActiveClass() {
      return this.showNewEventActions ? "active" : "";
    },

    /**
     * Return icon class for new event button
     */
    newEventIconClass() {
      return this.showNewEventActions ? "la la-times" : "la la-plus";
    },
  },
  watch: {
    /**
     * Watch for currentPlace change to set calendars business hours
     */
    currentPlace() {
      this.filters.placeId = this.currentPlace.id;
      this.setCalendarBusinessHours();
    },

    /**
     * Watch for any offcanvas element is open
     */
    isOffcanvasOpen(isOffcanvasOpen) {
      this.showNewEventButton = !isOffcanvasOpen;
    },

    /**
     * Watch for filters.calendarIds change
     */
    "filters.calendarIds": {
      immediate: false,
      deep: true,
      handler(newValue, oldValue) {
        if (newValue) {
          if (newValue.length > 0) {
            if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
              if (this.filtersReady()) {
                this.filterCalendarEvents();
              }
            }
          }
        }
      },
    },

    /**
     * Watch for filters.from change
     */
    "filters.to": {
      immediate: true,
      deep: true,
      handler() {
        if (this.filtersReady()) {
          this.filterCalendarEvents();
        }
      },
    },

    /**
     * Watch for allPlaces change
     */
    allPlaces: {
      handler() {
        if (this.filtersReady()) {
          this.filterCalendarEvents();
        }
      },
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/sass/bb-styles/pages/calendar.scss";
</style>
